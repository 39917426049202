/* markers */
.marker {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 36px;
}

.marker-arrow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform-origin: 50% 35%;
}

.marker-text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 50%;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  padding-top: 3px;
}

.webcam {
  padding: 18px 8px 14px 8px;
  border-radius: 8px;
  cursor: pointer;
}

.webcam-text-name {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: start;
}

.webcam-text-date {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: end;
}

.sounding .carousel .control-prev.control-arrow::before {
  border-right: 8px solid #333333 !important;
}

.sounding .carousel .control-next.control-arrow::before {
  border-left: 8px solid #333333 !important;
}

/* popups */
.mapboxgl-popup-content {
  border-radius: 8px;
  padding-bottom: 8px;
}

.mapboxgl-popup-content > p {
  margin: 0;
}

.mapboxgl-popup-anchor-left {
  padding-left: 30px;
}

.mapboxgl-popup-anchor-right {
  padding-right: 30px;
}

.mapboxgl-popup-anchor-top {
  padding-top: 20px;
}

.mapboxgl-popup-anchor-top-left {
  padding-top: 10px;
  padding-left: 20px;
}

.mapboxgl-popup-anchor-top-right {
  padding-top: 10px;
  padding-right: 20px;
}

.mapboxgl-popup-anchor-bottom {
  padding-bottom: 30px;
}

.mapboxgl-popup-anchor-bottom-left {
  padding-bottom: 20px;
  padding-left: 20px;
}

.mapboxgl-popup-anchor-bottom-right {
  padding-bottom: 20px;
  padding-right: 20px;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  display: none;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  display: none;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  display: none;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  display: none;
}
