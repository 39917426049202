.map-layer-icon {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><path d=\'m11.99 18.54-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z\'></path></svg>');
  background-origin: border-box;
  background-position: 3px 3px;
  background-repeat: no-repeat;
}

.map-unit-icon {
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
}
